import React, { useEffect, useState } from "react";
import "./richText.scss";
import {
  UnderlineOutlined,
  ItalicOutlined,
  BoldOutlined,
  VideoCameraOutlined,
  FileImageOutlined,
  FontColorsOutlined,
  ClearOutlined,
  UnorderedListOutlined,
  DownOutlined,
  FontSizeOutlined,
} from "@ant-design/icons";
import { BaseApi } from "../../../requests/base-api";
import { imageAddPrefix, isAssetTypeAnImage } from "../../../utils";
import { ContentType } from "../add";
import { Button, Upload, Dropdown, Menu } from "antd";
import { HexColorPicker, RgbaColorPicker } from "react-colorful";
import $ from "jquery";
// import { SketchPicker } from "react-color";

interface Props {
  content: any;
  setLoading: (res: any) => void;
  onChange: (res: any) => void;
}
function RichText(props: Props) {
  const { content: ct, onChange, setLoading } = props;
  const divRef = React.createRef<HTMLDivElement>();
  const [color, setColor] = useState("#aabbcc");
  // rgba用法如下
  // const [color, setColor] = useState({ r: 200, g: 150, b: 35, a: 0.5 });

  const [colorVisible, setColorVisible] = useState<boolean>(false);
  const [content, setContent] = useState<string | any[]>("");
  useEffect(() => {
    setContent(ct);
    divRef.current?.addEventListener(
      "wheel",
      (e) => {
        e.stopPropagation();
        // e.preventDefault();
      },
      { passive: false }
    );
    return () => {
      divRef.current?.removeEventListener("wheel", (e) => {
        e.stopPropagation();
        // e.preventDefault();
      });
    };
  }, []);

  useEffect(() => {
    // 初始化时触发更新父组件，临时规避未做任何修改保存失败的问题
    emitChange();
  }, [content]);

  const emitChange = (e?: any) => {
    // var html = this.getDOMNode().innerHTML;
    // 方便兼容旧的存储格式逻辑
    let content = divRef.current?.innerHTML
      .replace(/"/g, '\\"')
      .replace(/'/g, '\\"')
      .split('<img src=\\"/assets/video.webp?')
      .join("||||")
      .split('<img src=\\"')
      .join("||||")
      .split('\\" alt=\\"\\">')
      .join("||||");
    const contentArr = content?.split("||||");
    const arr = contentArr?.map((item: string) => {
      if (item.startsWith("http")) {
        return {
          content: item,
          type: isAssetTypeAnImage(item)
            ? ContentType.image
            : ContentType.video,
        };
      } else {
        return { content: item, type: ContentType.text };
      }
    });
    onChange && onChange(arr);
  };

  const uploadFile = async (file: any, callback?: Function) => {
    setLoading(true);
    try {
      let formdata = new FormData();
      formdata.append("file", file);
      const res = await BaseApi.uploadFile(formdata);
      setLoading(false);
      if (res.statusCode === 200) {
        callback && callback(res.url);
      }
    } catch (error) {}
  };

  const formatDoc = (type: string, value?: string) => {
    const textValue = divRef.current;
    if (!value) {
      document.execCommand(type, false);
    } else {
      if (type === "fontSize") {
        var spanString = $("<span/>", {
          text: document.getSelection(),
        })
          .css("font-size", value + "px")
          .prop("outerHTML");

        document.execCommand("insertHTML", false, spanString);
      } else {
        document.execCommand(type, false, value);
      }
    }
    // document.execCommand(
    //   "InsertImage",
    //   false,
    //   "https://shushang100.com//fileUpload/2022-12-14/1副本.png"
    // );
  };
  const HTML =
    typeof content == "object"
      ? content
          .map((item: any) => {
            return item.type === ContentType.text
              ? item.content
              : item.type === ContentType.image
              ? `<img src='${imageAddPrefix(item.content)}' alt/>`
              : `<img src='/assets/video.webp?${imageAddPrefix(
                  item.content
                )}' alt/>`;
          })
          .join("")
      : content;

  return (
    <div className="rich-text-container" onClick={() => setColorVisible(false)}>
      <div className="rich-text-tool">
        {/* <Button type="link" onClick={formatStyle}>
          加粗
        </Button> */}
        {/* <div
        // div 要阻止mousedown事件 不然会抢占焦点
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => formatDoc("bold")}
        >
          <BoldOutlined />
        </div> */}
        {/* <img
          className="intLink"
          title="Bold"
          onClick={() => formatDoc("bold")}
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 896 896' focusable='false' data-icon='bold' width='1em' height='1em' fill='currentColor' aria-hidden='true'%3E%3Cpath d='M697.8 481.4c33.6-35 54.2-82.3 54.2-134.3v-10.2C752 229.3 663.9 142 555.3 142H259.4c-15.1 0-27.4 12.3-27.4 27.4v679.1c0 16.3 13.2 29.5 29.5 29.5h318.7c117 0 211.8-94.2 211.8-210.5v-11c0-73-37.4-137.3-94.2-175.1zM328 238h224.7c57.1 0 103.3 44.4 103.3 99.3v9.5c0 54.8-46.3 99.3-103.3 99.3H328V238zm366.6 429.4c0 62.9-51.7 113.9-115.5 113.9H328V542.7h251.1c63.8 0 115.5 51 115.5 113.9v10.8z'%3E%3C/path%3E%3C/svg%3E"
        />
        <img
          className="intLink"
          title="Italic"
          onClick={() => formatDoc("italic")}
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 896 896' focusable='false' data-icon='italic' width='1em' height='1em' fill='currentColor' aria-hidden='true'%3E%3Cpath d='M798 160H366c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h181.2l-156 544H229c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h432c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8H474.4l156-544H798c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z'%3E%3C/path%3E%3C/svg%3E"
        />
        <img
          className="intLink"
          title="Underline"
          onClick={() => formatDoc("underline")}
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 896 896' focusable='false' data-icon='underline' width='1em' height='1em' fill='currentColor' aria-hidden='true'%3E%3Cpath d='M824 804H200c-4.4 0-8 3.4-8 7.6v60.8c0 4.2 3.6 7.6 8 7.6h624c4.4 0 8-3.4 8-7.6v-60.8c0-4.2-3.6-7.6-8-7.6zm-312-76c69.4 0 134.6-27.1 183.8-76.2C745 602.7 772 537.4 772 468V156c0-6.6-5.4-12-12-12h-60c-6.6 0-12 5.4-12 12v312c0 97-79 176-176 176s-176-79-176-176V156c0-6.6-5.4-12-12-12h-60c-6.6 0-12 5.4-12 12v312c0 69.4 27.1 134.6 76.2 183.8C377.3 701 442.6 728 512 728z'%3E%3C/path%3E%3C/svg%3E"
        /> */}
        <div onMouseDown={(e) => e.preventDefault()} title="粗体">
          <Button
            type="link"
            icon={<BoldOutlined />}
            onClick={() => formatDoc("bold")}
          ></Button>
        </div>
        <div onMouseDown={(e) => e.preventDefault()} title="斜体">
          <Button
            type="link"
            icon={<ItalicOutlined />}
            onClick={() => formatDoc("italic")}
          ></Button>
        </div>
        <div onMouseDown={(e) => e.preventDefault()} title="下划线">
          <Button
            type="link"
            icon={<UnderlineOutlined />}
            onClick={() => formatDoc("underline")}
          ></Button>
        </div>
        <div
          style={{ position: "relative" }}
          onMouseDown={(e) => e.preventDefault()}
          onClick={(e) => {
            e.stopPropagation();
            setColorVisible(true);
          }}
          title="字体颜色"
        >
          <Button type="link" icon={<FontColorsOutlined />}></Button>
          {colorVisible && (
            <HexColorPicker
              style={{ position: "absolute" }}
              color={color}
              onChange={(color: string) => {
                formatDoc("ForeColor", color);
                setColor(color);
              }}
            />
          )}
        </div>
        <div onMouseDown={(e) => e.preventDefault()} title="插入图片">
          <Upload
            // name="logo"
            showUploadList={false}
            listType="picture"
            beforeUpload={(file) => {
              uploadFile(file, (url: string) => {
                formatDoc(
                  "insertHTML",
                  `<img src='${imageAddPrefix(url)}' alt/>`
                );
              });
              return false;
            }}
          >
            <Button type="link" icon={<FileImageOutlined />}></Button>
          </Upload>
          {/* <img
            className="intLink"
            title="InsertImage"
            onClick={() => formatDoc("InsertImage")}
            src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 896 896' focusable='false' data-icon='file-image' width='1em' height='1em' fill='currentColor' aria-hidden='true'%3E%3Cpath d='M553.1 509.1l-77.8 99.2-41.1-52.4a8 8 0 00-12.6 0l-99.8 127.2a7.98 7.98 0 006.3 12.9H696c6.7 0 10.4-7.7 6.3-12.9l-136.5-174a8.1 8.1 0 00-12.7 0zM360 442a40 40 0 1080 0 40 40 0 10-80 0zm494.6-153.4L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494z'%3E%3C/path%3E%3C/svg%3E"
          /> */}
        </div>
        <div onMouseDown={(e) => e.preventDefault()} title="插入视频">
          <Upload
            // name="logo"
            showUploadList={false}
            accept="video/*"
            beforeUpload={(file) => {
              uploadFile(file, (url: string) => {
                formatDoc(
                  "insertHTML",
                  `<img src='/assets/video.webp?${imageAddPrefix(url)}' alt/>`
                );
              });
              return false;
            }}
          >
            <Button type="link" icon={<VideoCameraOutlined />}></Button>
          </Upload>
        </div>
        <div
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => formatDoc("insertunorderedlist")}
          title="黑点列表"
        >
          <Button type="link" icon={<UnorderedListOutlined />}></Button>
        </div>
        <div onMouseDown={(e) => e.preventDefault()} title="设置字体大小">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <a
                    href="#"
                    onClick={() => {
                      formatDoc("fontSize", "12");
                    }}
                    style={{ fontSize: 12 }}
                  >
                    12px
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="#"
                    onClick={() => {
                      formatDoc("fontSize", "14");
                    }}
                    style={{ fontSize: 14 }}
                  >
                    14px
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="#"
                    onClick={() => {
                      formatDoc("fontSize", "15");
                    }}
                    style={{ fontSize: 15 }}
                  >
                    15px
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="#"
                    onClick={() => {
                      formatDoc("fontSize", "16");
                    }}
                    style={{ fontSize: 16 }}
                  >
                    16px
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="#"
                    onClick={() => {
                      formatDoc("fontSize", "17");
                    }}
                    style={{ fontSize: 17 }}
                  >
                    17px
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="#"
                    onClick={() => {
                      formatDoc("fontSize", "18");
                    }}
                    style={{ fontSize: 18 }}
                  >
                    18px
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="#"
                    onClick={() => {
                      formatDoc("fontSize", "20");
                    }}
                    style={{ fontSize: 20 }}
                  >
                    20px
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="#"
                    onClick={() => {
                      formatDoc("fontSize", "24");
                    }}
                    style={{ fontSize: 24 }}
                  >
                    24px
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="#"
                    onClick={() => {
                      formatDoc("fontSize", "28");
                    }}
                    style={{ fontSize: 28 }}
                  >
                    28px
                  </a>
                </Menu.Item>
              </Menu>
            }
          >
            <a>
              <FontSizeOutlined />
              {/* <DownOutlined /> */}
            </a>
          </Dropdown>
        </div>
        <div
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => formatDoc("RemoveFormat")}
          title="清除选定文字的格式"
        >
          <Button type="link" icon={<ClearOutlined />}></Button>
        </div>
      </div>
      <div
        ref={divRef}
        // onSelect={(e) => {
        // }}
        className="rich-text-box"
        onInput={emitChange}
        onBlur={emitChange}
        // onWheel={(e) => {
        //   e.stopPropagation();
        //   e.preventDefault();
        // }}
        // onMouseWheel={}
        contentEditable
        dangerouslySetInnerHTML={{
          __html: HTML,
        }}
      ></div>
    </div>
  );
}

export default RichText;
