export const imageAddPrefix = (url: string) => {
  if (!url) return "";
  return url.startsWith("http") ? url : window.systemConfig.imagePrefix + url;
};
export const isAssetTypeAnImage = (url: string) => {
  const index = url.lastIndexOf(".");
  const ext = url.substring(index + 1);
  return (
    ["png", "jpg", "jpeg", "bmp", "gif", "webp", "svg", "tiff", "psd"].indexOf(
      ext.toLowerCase()
    ) !== -1
  );
};

export const formatContent = (content: string) => {
  return (
    content
      .replace(/\n/g, "<br>")
      // .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
    // .replace(/color=\\"/g, 'style=\\"color:')
    // .replace(/\"/g, '\\/"')
  );
  // .replace(/style="/g, "style='")
  // .replace(/color="/g, "color='")
  // .replace(/">/g, "'>");
};
